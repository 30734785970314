<template>
  <div class="intro full">
    <van-nav-bar title="选择支付方式" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="pw_top">
      <div class="item_top d_f ali_c">
        <span>支付剩余时间</span>
        <van-count-down class="time" :time="time" v-if="isCutDown"/>
        <span class="time" v-else>订单已失效</span>
      </div>
      <div class="item_b ali_c d_f">
        <div>应付金额：</div>
        <div class="price grey" v-if="couponAmount > 0">￥{{price ? (price*buyNum/100) : 0}}</div>
        <div class="price" v-if="couponAmount > 0">￥{{price ? ((price*buyNum - couponAmount)/100) : 0}}</div>
        <div class="price" v-else>￥{{price ? (price*buyNum/100) : 0}}</div>
        <div class="coupon_tag flex_1"><div v-if="couponAmount > 0">消费劵 抵扣{{couponAmount / 100}}</div></div>

        <div class="d_f ali_c p_detail cur_p" v-if="!isOpen && info.length" @click="isOpen = true">
          <span>订单详情</span>
          <img src="./img/p_open.png" alt="">
        </div>
        <div class="d_f ali_c p_detail cur_p" v-if="isOpen" @click="isOpen = false">
          <span>收起详情</span>
          <img src="./img/p_close.png" alt="">
        </div>
      </div>
      <div class="p_d_out" v-if="isOpen">
        <div class="p_d">
          <div class="d_f j_s ali_c title">
            <div>{{info[0].commoditytName}}</div>
            <div class="num">X{{buyNum}}</div>
          </div>

          <div class="d_f j_s ali_c sub" v-for="(item,i) in info" :key="i" v-show="isRealName">
            <div class="name">{{item.visitorName}}</div>
            <!--<div class="num" :class="totalDiscountPoints > 0 ? '' : flex_1">{{item.certificateNumber}}</div>-->
            <div class="num">{{item.certificateNumber}}</div>
            <div class="coupon_tag flex_1"><div v-if="couponAmount > 0 && i === 0">消费劵 抵扣{{couponAmount / 100}}</div></div>
            <div class="price" v-if="couponAmount > 0 && i === 0">￥{{price ? ((price - couponAmount)/100).toFixed(2) : 0}}</div>
            <div class="price" v-else>￥{{price ? (price/100).toFixed(2) : 0}}</div>
          </div>

          <div class="date d_f ali_c">
            <div>使用日期：{{info[0].beginDate}}</div>
            <div>时间：{{info[0].timeSlot}}</div>
          </div>
        </div>
      </div>
    </div>
    <!--支付方式-->
    <!--<div class="p_pay">-->
    <div class="p_pay" v-if="isCutDown">
      <div class="pp_item d_f ali_c">
        <img src="./img/p_w.png" alt="">
        <span class="flex_1">微信支付</span>
        <div class="p_radio active d_f ali_c j_c">
          <div></div>
        </div>
      </div>
      <!--支付按钮-->
      <!--<div class="p_btn cur_p t_a" @click="pay">-->
      <div class="p_btn cur_p t_a" @click="pay" v-if="isCutDown">
        <span v-if="couponAmount > 0">立即支付￥{{price ? ((price*buyNum - couponAmount)/100).toFixed(2) : 0}}</span>
        <span v-else>立即支付￥{{price ? (price*buyNum/100).toFixed(2) : 0}}</span>
      </div>
    </div>
  </div>
</template>

<script>

  import { Dialog, Toast, NavBar, Icon, CountDown } from 'vant';
  import md5 from 'js-md5';
  let loading = null;
  export default {
    name: "payWay",
    components:{
      'van-nav-bar': NavBar,
      'van-count-down': CountDown,
      'van-icon': Icon,
    },
    data() {
      return {
        isOpen:false,//是否展开明细
        payway:1,//支付方式
        price:this.$route.query.price,
        code:this.$route.query.code,
        buyNum:this.$route.query.buyNum,
        couponAmount:this.$route.query.couponAmount,
        apis:{
          detail:'/stlsOrder/findOrderDetail',
          queryOrderList:'/stlsOrder/queryOrderList',
          queryOrderStatus: 'stlsOrder/queryOrderStatus', //查询支付状态
          pay:"/stlsOrder/createPayInfo",
          getSysPointConfig: 'point/getSysPointConfig', // 查询积分比例
        },
        time:0,
        isCutDown:true,
        info:[{}],
        interVal:null,
        sellerId:'',
        isJump:false,//z支付成功跳转
        isRealName: 1 // 是否实名制
      }
    },
    created(){

      let _t = this;
      window.appPayDone = (code)=> _t.appPayDone(code);
      _t.$iosBridge.registerhandler('appPayDone',function (code) {
        _t.appPayDone(code)
      })
      // _t.getSysPointConfig();
      _t.queryDetail();
      _t.queryOrderList(); // 查询订单获得积分
      _t.getStatus()
    },
    methods: {
      appPayDone(code){
        let _t = this;
        if(code == 0){
          _t.interVal = setInterval(function () {
            if(_t.isJump){
              Toast.clear();
              loading.clear();
              window.clearInterval(_t.interVal);
              clearInterval(_t.interVal);
              _t.interVal = null;
              _t.getStatus()
            }else{
              _t.getStatus()
            }
          },1000);
        }else if(code == -1){
          Toast.clear();
          loading.clear();
          Toast('支付失败');
          window.clearInterval(_t.interVal);
          _t.interVal = null;
        }else if(code == -2){
          Toast.clear();
          loading.clear();
          Toast('用户取消');
          window.clearInterval(_t.interVal);
          _t.interVal = null;
        }
      },
      appPay(info){
        let _t = this;
        if (_t.$global.modelState) {
          window.android.payByWeChart(info);
        } else {
          _t.$iosMethod(function(bridge) {
            bridge.callHandler('payByWeChart', info, function(data) {

            })
          })
        }
      },
      jsonInfo(c){
        var jsonData={}
        c=c.replace("{","").replace("}","");
        c=c.split(',')
        for(var i=0;i<c.length;i++){
          if(c[i].split('=').length === 2){
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(c[i].split('=')[1]).replace(/\s+/g,"")
          }else{
            let str = ''
            for(var j = 1;j<c[i].split('=').length;j++){
              str += (c[i].split('=')[j]).replace(/\s+/g,"")+'=';
            }
            jsonData[(c[i].split('=')[0]).replace(/\s+/g,"")]=(str.substring(0,str.length-1))
          }
        }
        return jsonData
      },
      pay(){
        let me = this;
        loading = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '支付中'
        });
        me.$post2({
          url:me.apis.pay,
          params: {
            orderNumber: me.code,
            payType:'app',
            // payType:'mweb',
            totalFee: me.price*me.buyNum - (me.couponAmount || 0),
            token:me.$global.token
          }
        }).then(res => {
          Toast.clear();
          loading.clear();
          let info = me.jsonInfo(res.data.formData);
          let timestamp = me.$global.modelState ? sessionStorage.getItem('timestamp') : Number(sessionStorage.getItem('timestamp'))/1000;
          let signInfo = {
            appid:info.appid,
            timestamp:timestamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            package: "Sign=WXPay",
            prepayid:info.prepay_id,
          };
          console.log(signInfo)
          let stringA=me.sortAsc(signInfo);
          let stringSignTemp=stringA+"&key="+this.$global.appPayKey;
          let sign=md5(stringSignTemp).toUpperCase(); //注：MD5签名方式
          console.log(sign)
          let paySend = {
            appid:info.appid,
            timestamp:timestamp,
            partnerid:info.mch_id,
            noncestr:info.nonce_str,
            packageName: "Sign=WXPay",
            prepayid:info.prepay_id,
            paySign:sign,
            signType:'',
            iOSLink:'',
          };
          me.appPay(JSON.stringify(paySend));
        }).catch(err => {
          Toast(err.message);
          window.clearInterval(this.interVal);
          me.interVal = null;
        });
      },
      // 查询积分比列
      getSysPointConfig(){
        let me = this;
        me.$post({
          url:me.apis.getSysPointConfig,
          params: {}
        }).then(res => {
          // console.log(res)
          me.integralExchangeMoneyScale = res.data.integralExchangeMoneyScale
        });
      },
      sortAsc(o){
        var n = [];
        for(var k in o) n.push(k);
        n.sort();
        for(var i=0,str='';i<n.length;i++){
          var v = o[n[i]];
          if(v){
            if({}.toString.call(v)=='[object Object]')
              v = sortAsc(v);
            else if({}.toString.call(v)=='[object Array]')
              v = JSON.stringify(v).replace(/:/g,'=');
          }
          str+='&'+n[i]+'='+v;
        }
        return str.slice(1);
      },
      getStatus(){
        let me = this;
        me.$get({
          url:me.apis.queryOrderStatus,
          closeToast:true,
          params: {
            orderNumber: me.code,
            sortName: 'order_number',
            token:me.$global.token
          }
        }).then(res => {
          if(res.orderStatus == 4 || res.orderStatus == 5 || res.orderStatus == 7){
              Toast.clear();
              window.clearInterval(me.interVal);
              me.interVal = null;
              me.isJump = true;
              if(me.isJump){
                setTimeout(() => {
                  this.$router.push({
                    path:'/paySuccess',
                    query:{
                      orderNumber: me.code,
                      phone: res.reservationPersonTelephone,
                      sellerId: me.info[0].sellerId,
                      back:this.$route.query.back
                    }
                  })
                },1000)
              }
          }
        });
      },
      // 查询订单获得积分
      queryOrderList(){
        let me = this;
        me.$get({
          url:me.apis.queryOrderList,
          closeToast:true,
          params: {
            orderNumber: me.code,
            sortName: 'order_number',
            token:me.$global.token,
          }
        }).then(res => {
          // console.log(res)
          if(res.data.length){
            me.isRealName = res.data[0].order.isRealName
            for(let i in res.data){
              if(res.data[i].order.orderNumber == me.code){
                me.totalObtainPoint = res.data[i].order.totalObtainPoint
              }
            }
          }
        })
      },
      //查询详情
      queryDetail(){
        let me = this;
        me.$get({
          url:me.apis.detail,
          params: {
            orderNumber: me.code,
            token:me.$global.token,
          }
        }).then(res => {
          me.info = res;
          me.buyNum = me.info.length;
          me.sellerId = res[0].sellerId;
          if(me.price == 0){
            me.getStatus()
          }else{
            if(me.info[0].orderStatus === 0 || me.info[0].orderStatus === 1){
              let now = new Date().getTime();
              let order = new Date(me.$renderTime(me.info[0].creatorTime)).getTime();
              if((order + 15 * 60 * 1000)>now){
                me.time = (order + 15 * 60 * 1000) - now;
              }else{
                me.isCutDown = false;
              }
            }else{
              me.isCutDown = false;
            }
          }
        });
      },
      exit(){
        Dialog.confirm({
          cancelButtonText: '放弃',
          confirmButtonText: '继续支付',
          message: '是否放弃本次支付？',
        })
          .then(() => {
          })
          .catch(() => {
            // this.$destroy('KeepAlive');
            window.clearInterval(this.interVal);
            clearInterval(this.interVal);
            sessionStorage.clear();
            sessionStorage.setItem('token',this.$global.token);
            sessionStorage.setItem('LOGIN_STATUS','2');
            this.interVal = null;
            if(this.$route.query.back == 'tc'){
              this.$router.go(-1)
            }else{
              this.$router.push('/')
            }
          });
      },
    },
    beforeRouteLeave(to, from, next) {
      window.clearInterval(this.interVal);
      sessionStorage.clear();
      sessionStorage.setItem('LOGIN_STATUS','2');
      sessionStorage.setItem('token',this.$global.token);
      next();
    }
  }
</script>

<style scoped lang="scss">
  .coupon_tag{
    min-width: 84px;
    div{
      padding: 0 3px;
      height: 18px;
      border: 1px solid #FF3E6E;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FF3E6E;
    }
  }
  .p_btn{
    margin-top: 40px;
    height:40px;
    background:rgba(63,212,103,1);
    border:1px solid rgba(63,212,103,1);
    border-radius:5px;
    line-height: 40px;
    font-size:16px;

    font-weight:bold;
    color:rgba(255,255,255,1);;
  }
  .p_pay{
    .pp_item{
      .p_radio{
        width: 21px;
        height: 21px;
        border:1px solid rgba(204,204,204,1);
        border-radius: 50%;
        box-sizing: border-box;
        &.active{
          border-color: #3FD467;
          >div{
            box-sizing: border-box;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background-color: #3FD467;
          }
        }
      }
      .flex_1{
        font-size:14px;

        font-weight:500;
        color:rgba(20,18,18,1);
      }
      .p_z{
        width: 27px;
        margin-right: 17px;

      }
      .p_y{
        width: 32px;
        margin-right: 14px;

      }
      img{
        width: 29px;
        margin-right: 16px;
      }
      height:50px;
      background:rgba(255,255,255,1);
      border-radius:5px;
      margin-bottom: 13px;
      padding: 0 17px 0 13px;
    }
    padding: 22px 20px;
  }
  .pw_top{
    .p_d_out{
      .p_d{
        .date{
          padding: 0 13px;
          height:34px;
          line-height: 34px;
          background:rgba(244,244,244,1);
          border-top:1px solid rgba(204,204,204,1);
          font-size:12px;

          font-weight:bold;
          color:rgba(20,18,18,1);
          margin-top: 8px;
          >div{
            margin-right: 20px;
          }
        }
        .sub{
          .price{
            font-weight:bold;
            color:rgba(255,62,110,1);
            .jfdk{
              display: inline-block;
              vertical-align: middle;
              border: 1px solid #F62800;
              border-radius: 15px;
              padding: 0 5px;
              font-size: 8px;
              margin: -3px 0 0 5px;
            }
            span{
              color: #303030;
              margin-left: 5px;
            }
          }
          .name{
            width: 60px;
          }
          .num{
            margin-right: 10px;
          }
          margin-top: 15px;
          font-size:12px;

          font-weight:400;
          color:rgba(20,18,18,1);
          padding: 0 12px;
          &.mt_12{
            margin-top: 12px;
          }
        }
        .title{
          font-size:14px;

          font-weight:bold;
          color:rgba(20,18,18,1);
          padding: 0 12px;
        }
        background:rgba(255,255,255,1);
        border:1px solid rgba(204,204,204,1);
        border-radius:6px;
        padding: 12px 0 0 0;
        overflow: hidden;
      }
      padding:0 20px 16px 20px;
    }
    .item_b{
      .p_detail{
        img{
          width: 10px;
          margin-left: 8px;
        }
        font-size:12px;

        font-weight:400;
        color:rgba(87,87,87,1);
      }
      .price{
        color:rgba(255,56,126,1);
        margin-right: 15px;
        &.grey{
          color: #C7C7C7;
          text-decoration: line-through;
        }
        span{
          color: #A0A0A0;
          margin-right: 5px;
          text-decoration: line-through;
        }
        .jfdk{
          display: inline-block;
          vertical-align: middle;
          border: 1px solid #F62800;
          border-radius: 15px;
          padding: 0 5px;
          font-size: 8px;
          margin: -3px 0 0 5px;
        }
      }
      height: 43px;
      padding: 0 23px 0 26px;
      font-size:14px;

      font-weight:500;
      color:rgba(20,18,18,1);
    }
    .item_top{
      .time{
        margin-left: 20px;
      }
      border-bottom: 1px solid #E6E6E6;
      padding: 0 24px;
      height: 36px;
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    background-color: white;
  }
  .full{
    padding-top: 46px;
    background-color: #EEEEEE;
    .points{
      margin-top: 10px;
      background: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      line-height: 22px;
      font-size: 16px;
      img{
        height: 22px;
        vertical-align: text-bottom;
        margin-right: 10px;
      }
      .jf{
        float: right;
      }
      .reduce{
        color: #F62800;
      }
    }
  }

</style>



